import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { config } from '../config';
import { formatTimeAgo } from '../utils/formatters';
import Pagination from '../components/Pagination';
import NoImagePlaceholder from '../components/NoImagePlaceholder';
import NotesModal from '../components/NotesModal';

function RequestTable({ requests, pagination, type, onPageChange, onPageSizeChange }) {
  const [selectedRequest, setSelectedRequest] = useState(null);

  if (!requests?.length) {
    return (
      <div className="text-center py-4 text-gray-500">
        Nu există cereri de {type === 'movie' ? 'filme' : 'seriale'}
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-end mb-4">
        <Pagination
          currentPage={pagination.page}
          totalItems={pagination.total}
          pageSize={pagination.limit}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          pageSizeOptions={[5, 10, 25, 35]}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Poster
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Titlu
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                An
              </th>
              {type === 'series' && (
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sezon
                </th>
              )}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Solicitant
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Creat
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actualizat
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {requests.map((request, index) => (
              <tr key={request._id} className="even:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {(pagination.page - 1) * pagination.limit + index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {request.posterPath ? (
                    <a
                      href={`https://www.themoviedb.org/${request.type === 'series' ? 'tv' : 'movie'}/${request.tmdbId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-12 h-16 relative overflow-hidden rounded hover:opacity-75 transition-opacity border border-gray-200"
                    >
                      <img
                        src={`https://image.tmdb.org/t/p/w92${request.posterPath}`}
                        alt={request.title}
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                    </a>
                  ) : (
                    <a
                      href={`https://www.themoviedb.org/${request.type === 'series' ? 'tv' : 'movie'}/${request.tmdbId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-12 h-16 relative overflow-hidden rounded hover:opacity-75 transition-opacity border border-gray-200"
                    >
                      <div className="w-full h-full flex items-center justify-center bg-gray-100">
                        <NoImagePlaceholder className="w-6 h-6" />
                      </div>
                    </a>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {request.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {request.year}
                </td>
                {type === 'series' && (
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {request.requestedSeason}
                  </td>
                )}
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    {request.status ? (
                      <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                        style={{ backgroundColor: request.status.color + '20', color: request.status.color }}
                      >
                        {request.status.name}
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                        Status necunoscut
                      </span>
                    )}
                    {request.notes && (
                      <button
                        onClick={() => setSelectedRequest(request)}
                        className="p-1 hover:bg-gray-100 rounded-full"
                        title="Vezi note"
                      >
                        <svg 
                          className="w-5 h-5 text-primary-600"
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" 
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {request.requestedBy?.username || request.requestedByUsername || '[Utilizator șters]'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatTimeAgo(request.createdAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {request.updatedAt !== request.createdAt ? 
                    formatTimeAgo(request.updatedAt) : 
                    '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <NotesModal
        isOpen={!!selectedRequest}
        onClose={() => setSelectedRequest(null)}
        notes={selectedRequest?.notes}
        isAdmin={false}
      />
    </div>
  );
}

export default function Dashboard() {
  const { user } = useAuth();
  const [moviePage, setMoviePage] = useState(1);
  const [moviePageSize, setMoviePageSize] = useState(5);
  const [seriesPage, setSeriesPage] = useState(1);
  const [seriesPageSize, setSeriesPageSize] = useState(5);

  const { data: requestsData, isLoading, error } = useQuery({
    queryKey: ['requests', moviePage, moviePageSize, seriesPage, seriesPageSize],
    queryFn: async () => {
      const [movieResponse, seriesResponse] = await Promise.all([
        axios.get(`${config.apiUrl}/api/requests`, {
          headers: config.getAuthHeaders(),
          params: {
            page: moviePage,
            limit: moviePageSize,
            type: 'movie'
          }
        }),
        axios.get(`${config.apiUrl}/api/requests`, {
          headers: config.getAuthHeaders(),
          params: {
            page: seriesPage,
            limit: seriesPageSize,
            type: 'series'
          }
        })
      ]);

      return {
        movies: movieResponse.data,
        series: seriesResponse.data
      };
    }
  });

  if (isLoading) {
    return (
      <div className="text-center py-10">
        <div className="text-lg text-gray-600">Se încarcă...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <div className="text-lg text-red-600">A apărut o eroare la încărcarea cererilor</div>
      </div>
    );
  }

  // Get the paginated data and pagination info from the server response
  const movieRequests = requestsData?.movies?.results || [];
  const moviePagination = requestsData?.movies?.pagination || { page: 1, limit: 5, total: 0, totalPages: 1 };
  const seriesRequests = requestsData?.series?.results || [];
  const seriesPagination = requestsData?.series?.pagination || { page: 1, limit: 5, total: 0, totalPages: 1 };

  return (
    <div className="space-y-8 px-4 sm:px-0">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="space-y-2">
          <h1 className="text-2xl font-bold text-gray-900">Cereri</h1>
          <p className="text-sm text-gray-700">
            Listă cu toate cererile de filme și seriale
          </p>
        </div>
        <div className="mt-4 sm:mt-0">
          <Link
            to="/request"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Cerere Nouă
          </Link>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Filme</h2>
          <RequestTable 
            requests={movieRequests} 
            type="movie"
            pagination={moviePagination}
            onPageChange={setMoviePage}
            onPageSizeChange={setMoviePageSize}
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Seriale</h2>
          <RequestTable 
            requests={seriesRequests} 
            type="series"
            pagination={seriesPagination}
            onPageChange={setSeriesPage}
            onPageSizeChange={setSeriesPageSize}
          />
        </div>
      </div>
    </div>
  );
}
